import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const CHANNELS_URI = new InjectionToken<string>('Channels URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrl}${config.apiUrlPrefix}/channels`;
  },
});

export const CHANNELS_BFF_URI = new InjectionToken<string>('Channels BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/channels`;
  },
});
